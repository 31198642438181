import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

import { ClientProject } from '../shared/utilities/types.interfaces';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnChanges {
    @Input() public project: ClientProject;

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.project && this.project && this.project.apps) {
            this.project.apps.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        }
    }
}
