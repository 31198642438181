import { Component, ViewEncapsulation } from '@angular/core';

import { ClientProject } from './shared/utilities/types.interfaces';
import { PROJECTS } from '../environments/applications';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './styles/app.component.scss',
        './styles/custom-element.styles.scss',
        './styles/native-element.styles.scss',
    ],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    readonly projects: ClientProject[] = PROJECTS;

    constructor() {
        this.projects.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }
}
