import { ClientProject } from 'src/app/shared/utilities/types.interfaces';

export const PROJECTS: ClientProject[] = [
    {
        id: 'placeos',
        name: 'PlaceOS',
        logo: 'assets/logo.svg',
        colour: '#0a0d2e',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://templates.demo.place.tech/workplace/?mock=true',
            },
            {
                icon: 'kiosk',
                name: 'Kiosk',
                link: 'https://templates.demo.place.tech/map-kiosk/?mock=true',
            },
            {
                icon: 'bookings',
                name: 'Booking Panel',
                link: 'https://templates.demo.place.tech/booking-panel/?mock=true',
            },
            {
                icon: 'concierge',
                name: 'Concierge Dashboard',
                link: 'https://templates.demo.place.tech/concierge/?mock=true',
            },
            {
                icon: 'room',
                name: 'Room UI(Touch 10)',
                link: 'https://aca.im/demo/aca/room/',
            },
            {
                icon: 'control',
                name: 'Control UI',
                link: 'https://aca.im/demo/aca/control/',
            },
            {
                icon: 'control',
                name: 'Control Admin UI',
                link: 'https://control-admin.demo.place.tech/',
            },
            {
                icon: 'catering',
                name: 'Catering UI',
                link: 'https://templates.demo.place.tech/caterer-ui/?mock=true',
            },
            {
                icon: 'social',
                name: 'Social Feed',
                link: 'https://aca.im/demo/aca/social/',
            },
            {
                icon: 'timetable',
                name: 'Meeting Timetable',
                link: 'https://templates.demo.place.tech/timetable/?mock=true&sys_ids=space-0,space-1',
            },
            {
                icon: 'backoffice',
                name: 'Backoffice',
                link: 'https://backoffice.demo.place.tech/?mock=true',
            },
        ],
    },
    {
        id: 'amp',
        name: 'AMP Capital',
        logo: 'assets/logo/amp-icon.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/ampc/staff/',
            },
        ],
    },
    {
        id: 'arrb',
        name: 'ARRB',
        logo: 'assets/logo/arrb-icon.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/arrb/staff/',
            },
            {
                icon: 'kiosk',
                name: 'Kiosk',
                link: 'https://aca.im/demo/arrb/kiosk/',
            },
        ],
    },
    {
        id: 'arup',
        name: 'ARUP',
        logo: 'assets/logo/arup-logo.svg',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/arup/staff/',
            },
            {
                icon: 'kiosk',
                name: 'Kiosk',
                link: 'https://kiosk.arup.demo.place.tech/',
            },
            {
                icon: 'bookings',
                name: 'Booking Panel',
                link: 'https://aca.im/demo/arup/bookings/',
            },
        ],
    },
    {
        id: 'aurizon',
        name: 'Aurizon',
        logo: 'assets/logo/aurizon-logo.svg',
        apps: [
            {
                icon: 'kiosk',
                name: 'Welcome Board',
                link: 'https://aca.im/demo/aurizon/arrivals-board/',
            },
        ],
    },
    {
        id: 'aurecon',
        name: 'Aurecon',
        logo: 'assets/logo/aurecon-icon.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/aurecon/staff/',
            },
            {
                icon: 'kiosk',
                name: 'Kiosk(Staff)',
                link: 'https://aca.im/demo/arup/kiosk/#/bootstrap/zone-lvl_01',
            },
            {
                icon: 'kiosk',
                name: 'Kiosk(Client)',
                link: 'https://aca.im/demo/arup/kiosk/#/bootstrap/zone-lvl_01',
            },
            {
                icon: 'concierge',
                name: 'Concierge Dashboard',
                link: 'https://aca.im/demo/aurecon/concierge/',
            },
            {
                icon: 'bookings',
                name: 'Booking Panel',
                link: 'https://aca.im/demo/aurecon/bookings/',
            },
            {
                icon: 'room',
                name: 'Room UI(Touch 10)',
                link: 'https://aca.im/demo/aurecon/room/',
            },
        ],
    },
    {
        id: 'china-taiping',
        name: 'China Taiping',
        logo: 'assets/logo/china-taiping-icon.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/china-taiping/staff/',
            },
            {
                icon: 'kiosk',
                name: 'Kiosk',
                link: 'https://aca.im/demo/china-taiping/kiosk/',
            },
        ],
    },
    {
        id: 'cisco',
        name: 'Cisco',
        logo: 'assets/logo/cisco.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/cisco/staff/',
            },
        ],
    },
    {
        id: 'deakin',
        name: 'Deakin University',
        logo: 'assets/logo/deakin.svg',
        apps: [
            {
                icon: 'control',
                name: 'AV Control',
                link: 'https://control.deakin.demo.place.tech/',
            },
        ],
    },
    {
        id: 'doe',
        name: 'Department of Education',
        logo: 'assets/logo/nsw-gov-icon.png',
        apps: [
            {
                icon: 'staff',
                name: 'Explore App',
                link: 'https://aca.im/demo/doe/explore/',
            },
        ],
    },
    {
        id: 'didata',
        name: 'Dimension Data',
        logo: 'assets/logo/didata-icon.png',
        colour: '#71c044',
        apps: [
            {
                icon: 'bookings',
                name: 'Booking Panel',
                link: 'https://aca.im/demo/didata/bookings/',
            },
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/didata/staff/',
            },
        ],
    },
    {
        id: 'google',
        name: 'Google',
        logo: 'assets/logo/google-logo.svg',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/google/staff/',
            },
        ],
    },
    {
        id: 'iag',
        name: 'IAG',
        logo: 'assets/logo/iag-icon.png',
        colour: '#582583',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/iag/app/',
            },
        ],
    },
    {
        id: 'latitude',
        name: 'Latitude Financial',
        logo: 'assets/logo/latitude-icon.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/latitude/staff/',
            },
            {
                icon: 'kiosk',
                name: 'Kiosk',
                link: 'https://aca.im/demo/latitude/kiosk/',
            },
        ],
    },
    {
        id: 'lendlease',
        name: 'LendLease',
        logo: 'assets/logo/lendlease-icon.jpg',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/lendlease/staff/',
            },
            {
                icon: 'staff',
                name: 'Tenant App',
                link: 'https://aca.im/demo/lendlease/tenants/',
            },
        ],
    },
    {
        id: 'national-bank-canada',
        name: 'Le Banque Nationale',
        logo: 'assets/logo/bnc-logo.svg',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App(English)',
                link: 'https://bnc.staff.demo.place.tech/en-US/',
            },
            {
                icon: 'staff',
                name: 'Staff App(French)',
                link: 'https://bnc.staff.demo.place.tech/fr/',
            },
        ],
    },
    {
        id: 'ntt',
        name: 'NTT',
        logo: 'assets/logo/ntt-logo.jpg',
        apps: [
            {
                icon: 'staff',
                name: 'Workplace App',
                link: 'https://ntt.demo.place.tech/workplace/?mock=true',
            },
            {
                icon: 'concierge',
                name: 'Concierge App',
                link: 'https://ntt.demo.place.tech/concierge/?mock=true',
            },
            {
                icon: 'bookings',
                name: 'Booking Panel',
                link: 'https://ntt.demo.place.tech/booking-panel/?mock=true',
            },
            {
                icon: 'control',
                name: 'Control App',
                link: 'https://ntt.demo.place.tech/control/?mock=true',
            },
        ],
    },
    {
        id: 'pwcsg',
        name: 'PwC Singapore',
        logo: 'assets/logo/pwc-icon.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/pwcsg/',
            },
        ],
    },
    {
        id: 'rabobank',
        name: 'Rabobank',
        logo: 'assets/logo/rabobank-icon.png',
        apps: [],
    },
    {
        id: 'suncorp',
        name: 'Suncorp',
        logo: 'assets/logo/suncorp-logo.svg',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App(Old)',
                link: 'https://aca.im/demo/suncorp/staff/',
            },
            {
                icon: 'staff',
                name: 'Staff App(New)',
                link: 'https://aca.im/demo/lendlease/staff-new/',
            },
        ],
    },
    {
        id: 'suntec',
        name: 'Suntec',
        logo: 'assets/logo/suntec-icon.jpg',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/suntec/app/',
            },
        ],
    },
    {
        id: 'td-bank',
        name: 'TD Bank',
        logo: 'assets/logo/td-bank-logo.png',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/td-bank/staff/',
            },
        ],
    },
    {
        id: 'towers',
        name: 'International Towers',
        logo: 'assets/logo/towers-logo.svg',
        apps: [
            {
                icon: 'staff',
                name: 'Client App',
                link: 'https://aca.im/demo/towers/client/',
            },
        ],
    },
    {
        id: 'westmead',
        name: 'Westmead',
        logo: 'assets/logo/nsw-gov-icon.png',
        apps: [
            {
                icon: 'kiosk',
                name: 'Kiosk',
                link: 'https://aca.im/demo/westmead/kiosk/',
            },
        ],
    },
    {
        id: 'windsor',
        name: 'Windsor Hotel',
        logo: 'assets/logo/windsor-icon.png',
        colour: '#ddd',
        apps: [
            {
                icon: 'staff',
                name: 'Staff App',
                link: 'https://aca.im/demo/windsor-hotel/app/',
            },
        ],
    },
];
