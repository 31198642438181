
import { Directive, OnDestroy } from '@angular/core';
import { BaseClass } from './base.class';

@Directive({
    selector: 'a-very-basic-component-base-that-should-not-be-used'
})
export class BaseDirective extends BaseClass implements OnDestroy {
    public ngOnDestroy(): void {
        this.destroy();
    }
}

