<div class="project-details" *ngIf="project">
    <div class="logo" [style.background-color]="project.colour">
        <div
            class="image"
            [style.background-image]="'url(' + project.logo + ')'"
        ></div>
    </div>
    <div class="details">
        <div class="name">{{ project.name }}</div>
        <div class="count">
            {{ project.apps.length || 0 }}
            { project.apps.length, plural, =1 { app } other { apps } }
        </div>
        <button
            mat-icon-button
            [matMenuTriggerFor]="appMenu"
            *ngIf="project.apps && project.apps.length"
        >
            <app-icon
                [icon]="{ class: 'material-icons', content: 'more_vert' }"
            ></app-icon>
        </button>
    </div>
</div>
<mat-menu #appMenu="matMenu" xPosition="before">
    <ng-container *ngIf="project">
        <a
            mat-menu-item
            *ngFor="let app of project.apps"
            [href]="app.link | safe: 'url'"
            target="_blank"
            >{{ app.name }}</a
        >
    </ng-container>
</mat-menu>
