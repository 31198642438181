import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { APipesModule } from '@acaprojects/ngx-pipes';

import { TopbarHeaderComponent } from './components/topbar-header/topbar-header.component';
import { IconComponent } from './components/icon/icon.component';

const MATERIAL_MODULES: any[] = [
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
];

const EXTERNAL_MODULES: any[] = [APipesModule];

@NgModule({
    declarations: [TopbarHeaderComponent, IconComponent],
    imports: [CommonModule, ...MATERIAL_MODULES, ...EXTERNAL_MODULES],
    exports: [
        TopbarHeaderComponent,
        IconComponent,
        ...MATERIAL_MODULES,
        ...EXTERNAL_MODULES,
    ],
})
export class SharedContentModule {}
