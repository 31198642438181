import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ProjectComponent } from './project/project.component';
import { SharedContentModule } from './shared/shared.module';

@NgModule({
    declarations: [AppComponent, ProjectComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        SharedContentModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
